/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GettingStartedGetBranchRequest from "Communication/ApiClasses/GettingStartedGetBranchRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGettingStartedResident(action) {
  yield put(doLoadingStart(STRINGS.GETTING_STARTED.API_KEYS.BRANCH_LIST));
  const branchData = action.data;
  const branchApi = new GettingStartedGetBranchRequest(branchData);
  try {
    const result = yield call(branchApi.getData);
    yield put({
      type: actionTypes.GETTINGSTARTED_BRANCH_RESPONSE,
      success: result,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GETTINGSTARTED_BRANCH_ERROR,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.GETTING_STARTED.API_KEYS.BRANCH_LIST));
  }
}

// watcher
export function* gettingStartedResidentWatcherSaga() {
  yield takeLatest(
    actionTypes.GETTINGSTARTED_BRANCH_REQUEST,
    handleGettingStartedResident
  );
}
