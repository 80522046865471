import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import StatusRequest from "Communication/ApiClasses/EmploymentStatusApiRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleStatus() {
  yield put(doLoadingStart(STRINGS.EMPLOYMENT_AND_INCOME.API_KEYS.STATUS_LIST));
  const statusApi = new StatusRequest();
  try {
    const result = yield call(statusApi.getData);
    yield put({
      type: actionTypes.ADD_STATUS_LIST,
      statusList: result.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.STATUS_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.EMPLOYMENT_AND_INCOME.API_KEYS.STATUS_LIST)
    );
  }
}

// watcher
export default function* statusWatcherSaga() {
  yield takeLatest(actionTypes.GET_STATUS_LIST, handleStatus);
}
