import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetProductVaultRequest from "Communication/ApiClasses/GetProductVaultRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import { updateVaultProductList } from "Components/MarketPlace/Action/MarketPlaceAction";
import STRINGS from "Constants/Strings";
import { IS_LOGOUT } from "Communication/Constants";
import { IS_SET_PASSWORD_VISIT } from "../../../Communication/Constants";

// worker
function* handleGetProductVaultRequest(action) {
  yield put(doLoadingStart(STRINGS.MARKETPLACE.API_KEYS.GET_PRODUCT_VAULT));
  const getProductVaultApi = new GetProductVaultRequest();
  try {
    const result = yield call(getProductVaultApi.getData);
    /* When page refreshed componentDidMount function of that page/component is get called and we called logout action, that componentDidMount function consist call of getProductfromVault.
      This getProductfromVault API call we get "Add to Vault product list" in reponse and store it in reducer.
      If logout get called on refresh we are not storing getProductfromVault API response("Add to Vault product list") in reducer.
    */
    const isSetPasswordVisit = sessionStorage.getItem(IS_SET_PASSWORD_VISIT);
    if (
      JSON.parse(sessionStorage.getItem(IS_LOGOUT)) !== true &&
      isSetPasswordVisit !== "true"
    ) {
      console.log("update vault 9");
      yield put(updateVaultProductList(result.data));
      yield call(action.callback, result);
    }
  } catch (e) {
    yield call(action.callback, e.response);
  } finally {
    yield put(doLoadingFinish(STRINGS.MARKETPLACE.API_KEYS.GET_PRODUCT_VAULT));
  }
}

// watcher
export default function* getProductVaultWatcherSaga() {
  yield takeEvery(
    actionTypes.GET_PRODUCT_FROM_VAULT,
    handleGetProductVaultRequest
  );
}
