import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  createEsignResponse: null,
  sendEsignResponse: null,
  esignInfo: null,
  success: "",
  error: "",
};

const SignatureReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ESIGN_CREATE_SUCCESS:
      return {
        ...state,
        success: true,
        error: "",
        createEsignResponse: action.response.data,
      };
    case actionTypes.ESIGN_CREATE_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
        createEsignResponse: null,
      };
    case actionTypes.ESIGN_SEND_SUCCESS:
      return {
        ...state,
        success: true,
        error: "",
        sendEsignResponse: action.response.data,
      };
    case actionTypes.ESIGN_SEND_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
        sendEsignResponse: null,
      };
    case actionTypes.ESIGN_GET_SUCCESS:
      return {
        ...state,
        esignInfo: action.response.data,
      };
    case actionTypes.ESIGN_GET_ERROR:
      return {
        ...state,
        error: action.error,
        esignInfo: null,
      };
    default:
      return state;
  }
};

export default SignatureReducer;
