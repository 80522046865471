import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import DeleteLoanFinancial from "Communication/ApiClasses/DeleteLoanFinancialRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleDeleteLoanFinancial(action) {
  yield put(
    doLoadingStart(STRINGS.LOAN_DETAILS.API_KEYS.LOAN_DELETE_FINANCIAL)
  );
  const deleteLoanFinancial = new DeleteLoanFinancial(
    action.data.applicantId,
    action.data.finAnalysisId,
    action.data.financialId,
    action.data.payLoad
  );
  try {
    const result = yield call(deleteLoanFinancial.getData);
    yield put({
      type: actionTypes.LOAN_DELETE_FINANCIAL_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.LOAN_DELETE_FINANCIAL_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.LOAN_DETAILS.API_KEYS.LOAN_DELETE_FINANCIAL)
    );
  }
}

// watcher
function* deleteLoanFinancialWatcherSaga() {
  yield takeEvery(actionTypes.LOAN_DELETE_FINANCIAL, handleDeleteLoanFinancial);
}

export default deleteLoanFinancialWatcherSaga;
