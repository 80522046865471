import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetBeneficiaryRelationApiRequest from "Communication/ApiClasses/GetBeneficiaryRelationApiRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGetBeneficiaryRelation() {
  try {
    yield put(
      doLoadingStart(STRINGS.BENEFICIARIES.API_KEYS.GET_BENEFICIARY_RELATION)
    );
    const getBeneficiaryRelationApi = new GetBeneficiaryRelationApiRequest();
    const result = yield call(getBeneficiaryRelationApi.getData);
    yield put({
      type: actionTypes.ADD_BENEFICIARY_RELATION_LIST,
      beneficiaryRelationList: result.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_BENEFICIARY_RELATION_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.BENEFICIARIES.API_KEYS.GET_BENEFICIARY_RELATION)
    );
  }
}

// watcher
export default function* getBeneficiaryRelationWatcherSaga() {
  yield takeLatest(
    actionTypes.GET_BENEFICIARY_RELATION,
    handleGetBeneficiaryRelation
  );
}
