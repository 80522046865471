import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import SpousalInfo from "Communication/ApiClasses/SpousalInfoRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleSpousalInfoPurpose(action) {
  yield put(doLoadingStart(STRINGS.SPOUSAL_INFO.API_KEYS.SPOUSAL_DATA));

  const spousalInfoPurposeApi = new SpousalInfo(action.SpousalInfo, [
    action.applicationId,
    action.contractProductId,
  ]);
  try {
    const result = yield call(spousalInfoPurposeApi.getData);
    yield put({
      type: actionTypes.ADD_SET_SPOUSAL_INFO,
      setSpousalInfo: result.data,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.SET_SPOUSAL_INFO_ERROR,
      error: error.message,
    });
    yield call(action.callback, error.response);
  } finally {
    yield put(doLoadingFinish(STRINGS.SPOUSAL_INFO.API_KEYS.SPOUSAL_DATA));
  }
}

// watcher
export default function* spousalInfoPurposeWatcherSaga() {
  yield takeLatest(actionTypes.SET_SPOUSAL_INFO, handleSpousalInfoPurpose);
}
