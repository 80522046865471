import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import AddGlobalVault from "Communication/ApiClasses/AddGlobalVault";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleAddGlobalVault(action) {
  yield put(doLoadingStart(STRINGS.JOIN_APPLICANT.API_KEYS.ADD_GLOBAL_VAULT));
  const addGlobalVault = new AddGlobalVault(action.data);
  try {
    const result = yield call(addGlobalVault.getData);
    yield put({
      type: actionTypes.ADD_GLOBAL_VAULT_SUCCESS,
      response: result,
      formData: { ...action.requestData },
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.ADD_GLOBAL_VAULT_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.JOIN_APPLICANT.API_KEYS.ADD_GLOBAL_VAULT)
    );
  }
}

// watcher
function* addAddGlobalVaultWatcherSaga() {
  yield takeEvery(actionTypes.ADD_GLOBAL_VAULT, handleAddGlobalVault);
}

export default addAddGlobalVaultWatcherSaga;
