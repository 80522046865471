import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";

class LoanResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.LOAN_ADJUDICATION_RESULTS,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  setFormData = () => {};

  handleChange = (choice) => {
    const { handleChange } = this.props;
    handleChange(choice);
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    handleContinue();
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.LOAN_SUMMARY);
  };

  render() {
    const { choice } = this.state;
    const { steps, activeStepID } = this.props;
    console.log(steps, activeStepID);
    const actionComponent = (
      <div className="[ loan-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.LOAN_ADJUDICATION_RESULTS.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    choice !== "" && choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) => this.handleChange(true, event)}
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    choice !== "" && !choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) => this.handleChange(false, event)}
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

LoanResult.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

LoanResult.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default LoanResult;
