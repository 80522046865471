import React from "react";
import "./Backdrop.scss";

const backdrop = (props) =>
  props.show ? (
    <div
      className={
        props.top
          ? "[ backdrop-container ] [ backdrop-container__top ]"
          : "[ backdrop-container ]"
      }
      role="presentation"
    />
  ) : null;

export default backdrop;
