import * as actionTypes from "Redux/Actions/actionTypes";

export const addApplicationSlot = (requestData, callback = () => {}) => ({
  type: actionTypes.ADD_APPLICATION_SLOT,
  data: requestData,
  requestData,
  callback,
});

export const addJointApplicants = (requestData, callback = () => {}) => ({
  type: actionTypes.ADD_JOINT_APPLICANTS,
  data: requestData,
  callback,
});

export const inviteApplicationSlot = (requestData, callback = () => {}) => ({
  type: actionTypes.INVITE_APPLICATION_SLOT,
  data: requestData,
  requestData,
  callback,
});

export const getApplicantType = (callback = () => {}) => {
  return {
    type: actionTypes.GET_APPLICANT_TYPE,
    callback,
  };
};

export const getApplicationProperty = (requestData, callback = () => {}) => ({
  type: actionTypes.GET_APPLICATION_PROPERTY,
  data: requestData,
  requestData,
  callback,
});

export const deleteApplicationSlot = (requestData, callback = () => {}) => ({
  type: actionTypes.DELETE_APPLICATION_SLOT,
  data: requestData,
  requestData,
  callback,
});

export const addGlobalVault = (requestData, callback = () => {}) => ({
  type: actionTypes.ADD_GLOBAL_VAULT,
  data: requestData,
  requestData,
  callback,
});

export const updateGlobalVault = (requestData, callback = () => {}) => ({
  type: actionTypes.ADD_GLOBAL_VAULT,
  data: requestData,
  requestData,
  callback,
});

export const getGlobalVault = (requestData, callback = () => {}) => ({
  type: actionTypes.GET_GLOBAL_VAULT,
  data: requestData,
  requestData,
  callback,
});

export const applicantCreateRequest = (requestData, callback = () => {}) => ({
  type: actionTypes.APPLICANT_CREATE_REQUEST,
  data: requestData,
  requestData,
  callback,
});

export const applicantCreateSelf = (requestData, callback = () => {}) => ({
  type: actionTypes.APPLICANT_CREATE_SELF,
  data: requestData,
  requestData,
  callback,
});

export const otpVerifyInvite = (requestData, callback = () => {}) => ({
  type: actionTypes.OTP_VERIFY_INVITE,
  data: requestData,
  requestData,
  callback,
});

export const fillApplicationSlot = (requestData, callback = () => {}) => ({
  type: actionTypes.FILL_APPLICATION_SLOT,
  data: requestData,
  requestData,
  callback,
});

export const setInSessionJointApplicant = (
  requestData,
  callback = () => {}
) => ({
  type: actionTypes.SET_IN_SESSION_JOINT_APPLICANT,
  data: requestData,
  callback,
});

export const confirmApplicantCreate = (requestData, callback = () => {}) => ({
  type: actionTypes.APPLICANT_CREATE_CONFIRM,
  data: requestData,
  callback,
});

export const setOutSessionOtp = (requestData, callback = () => {}) => ({
  type: actionTypes.SET_JOINT_APPLICANT_OTP,
  data: requestData,
  callback,
});

export default otpVerifyInvite;
