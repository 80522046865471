import * as actionTypes from "Redux/Actions/actionTypes";

export const getCountryList = () => {
  return {
    type: actionTypes.GET_COUNTRY_LIST,
  };
};

export const getStatusList = () => {
  return {
    type: actionTypes.GET_STATUS_LIST,
  };
};

export const getIncomeSourcesList = () => {
  return {
    type: actionTypes.GET_INCOME_SOURCES_LIST,
  };
};

export const getEmployerPositionCodes = () => {
  return {
    type: actionTypes.GET_EMPLOYER_POSITION_CODES,
  };
};
