import BaseApiRequest from "./BaseApiRequest";
import { METHOD } from "../Constants";
import { INVERITE_CREATE_ACCOUNT_LIST_API_URL } from "../ApiUrls";

export default class CreateInveriteAccountListRequest extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = {};

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return INVERITE_CREATE_ACCOUNT_LIST_API_URL;
  };

  getBody = () => {
    return this.body;
  };
}
