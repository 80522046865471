import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetLoanAsset from "Communication/ApiClasses/GetLoanAssetRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGetLoanAsset(action) {
  yield put(doLoadingStart(STRINGS.ASSET.API_KEYS.LOAN_GET_ASSET));
  const getLoanAsset = new GetLoanAsset(
    action.data.applicantId,
    action.data.finAnalysisId,
    action.data.financialId
  );
  try {
    const result = yield call(getLoanAsset.getData);
    yield put({
      type: actionTypes.LOAN_GET_ASSET_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.LOAN_GET_ASSET_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.ASSET.API_KEYS.LOAN_GET_ASSET));
  }
}

// watcher
function* getLoanAssetWatcherSaga() {
  yield takeLatest(actionTypes.LOAN_GET_ASSET, handleGetLoanAsset);
}

export default getLoanAssetWatcherSaga;
