/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import CustomAxios from "./CustomAxios";
class CustomBaseApi {
  callApi(method, url, requestBody, header) {
    throw new Error("callApi: method not implemented");
  }
}
export default CustomBaseApi;

let api = null;

export function getApiImplementation() {
  const axiosInstance = new CustomAxios("");
  setApiImplementation(axiosInstance);
  return api;
}

export function setApiImplementation(apiObj) {
  api = apiObj;
}
