import { call, put, takeLatest } from "redux-saga/effects";
import GetOccupationCodes from "Communication/ApiClasses/GetOccupationCodes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import * as actionTypes from "Redux/Actions/actionTypes";

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(actionTypes.GET_OCCUPATION_CODES));
  const getOccupationCodes = new GetOccupationCodes();
  try {
    const result = yield call(getOccupationCodes.getData);
    yield put({
      type: `${actionTypes.GET_OCCUPATION_CODES}_SUCCESS`,
      response: result.data,
    });

    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: `${actionTypes.GET_OCCUPATION_CODES}_ERROR`,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(actionTypes.GET_OCCUPATION_CODES));
  }
}
// watcher
export default function* getSaga() {
  yield takeLatest(actionTypes.GET_OCCUPATION_CODES, handleSaga);
}
