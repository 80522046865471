import { call, put, takeLatest } from "redux-saga/effects";
import GetGettingStartedVaultRequest from "Communication/ApiClasses/GetGettingStartedVaultRequest";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGettingStarted(action) {
  yield put(
    doLoadingStart(STRINGS.GETTING_STARTED.API_KEYS.GETTING_STARTED_VAULT)
  );
  const getVault = new GetGettingStartedVaultRequest();
  try {
    const result = yield call(getVault.getData);
    yield put({
      type: actionTypes.STORE_GETTING_STARTED,
      data: result.data,
    });
    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: actionTypes.GETTING_STARTED_ERROR,
      error,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.GETTING_STARTED.API_KEYS.GETTING_STARTED_VAULT)
    );
  }
}

// watcher
export default function* gettingStartedWatcherSaga() {
  yield takeLatest(actionTypes.CALL_GETTING_STARTED_API, handleGettingStarted);
}
