import BaseApiRequest from "./BaseApiRequest";
import { GET_GETTING_STARTED_VAULT } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetGettingStartedVaultRequest extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = {};

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return GET_GETTING_STARTED_VAULT;
  };

  getBody = () => {
    return this.body;
  };
}
