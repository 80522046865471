import { call, put, takeLatest } from "redux-saga/effects";
import GetFinancialAnalysisList from "Communication/ApiClasses/GetFinancialAnalysisList";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// worker
function* handleGetFinancialAnalysisListSaga(action) {
  yield put(
    doLoadingStart(STRINGS.LOAN_DETAILS.API_KEYS.GET_FINANCIALANALYSYS_LIST)
  );
  console.log(action);
  const getFinancialAnalysisList = new GetFinancialAnalysisList(
    action.data.applicantId
  );
  try {
    const result = yield call(getFinancialAnalysisList.getData);
    yield put({
      type: actionTypes.GET_FINANCIALANALYSYS_LIST_SUCCESS,
      response: result.data,
      formData: { ...action.requestData },
    });

    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: actionTypes.GET_FINANCIALANALYSYS_LIST_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.LOAN_DETAILS.API_KEYS.GET_FINANCIALANALYSYS_LIST)
    );
  }
}
// watcher
export default function* getGetFinancialAnalysisListWatcherSaga() {
  yield takeLatest(
    actionTypes.GET_FINANCIALANALYSYS_LIST,
    handleGetFinancialAnalysisListSaga
  );
}
