import { call, put, takeLatest } from "redux-saga/effects";
import GetAccountRequest from "Communication/ApiClasses/GetAccountRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

// worker
function* handleGetAccount(action) {
  yield put(doLoadingStart(STRINGS.COMMON.API_KEYS.GET_ACCOUNT));
  const getAccount = new GetAccountRequest();
  try {
    const result = yield call(getAccount.getData);
    yield put({
      type: actionTypes.GET_ACCOUNT_SUCCESS,
      response: result.data,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACCOUNT_ERROR,
      error,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.COMMON.API_KEYS.GET_ACCOUNT));
  }
}

// watcher
export default function* getAccountWatcherSaga() {
  yield takeLatest(actionTypes.GET_ACCOUNT, handleGetAccount);
}
