import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import GetApplicantBankingProductList from "../../../Communication/ApiClasses/GetApplicantBankingProductList";

// worker
function* getBankingProductList(action) {
  yield put(
    doLoadingStart(STRINGS.ACCOUNT_INFORMATION.API_KEYS.BANKING_PRODUCT_LIST)
  );
  const getApplicantBankingProductList = new GetApplicantBankingProductList(
    action.data.applicantId
  );
  try {
    const result = yield call(getApplicantBankingProductList.getData);
    yield put({
      type: actionTypes.GET_APPLICANT_BANKING_PRODUCT_LIST_SUCCESS,
      data: result.data,
    });
    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: actionTypes.GET_APPLICANT_BANKING_PRODUCT_LIST_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.ACCOUNT_INFORMATION.API_KEYS.BANKING_PRODUCT_LIST)
    );
  }
}

// watcher
export default function* bankingProductListWatcher() {
  yield takeLatest(
    actionTypes.GET_APPLICANT_BANKING_PRODUCT_LIST,
    getBankingProductList
  );
}
