import { call, put, takeLatest } from "redux-saga/effects";
import DeleteApplicationRequest from "Communication/ApiClasses/DeleteApplicationRequest";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleDeleteApplicationProgress(action) {
  yield put(doLoadingStart(STRINGS.COMMON.API_KEYS.DELETE_APPLICATION));
  const deleteApplicationApi = new DeleteApplicationRequest(action.data);
  try {
    const result = yield call(deleteApplicationApi.getData);
    yield call(action.callback, result);
  } catch (error) {
    yield call(action.callback, error.response);
  } finally {
    yield put(doLoadingFinish(STRINGS.COMMON.API_KEYS.DELETE_APPLICATION));
  }
}

// watcher
export default function* deleteApplicationProgressWatcherSaga() {
  yield takeLatest(
    actionTypes.APPLICATION_DELETE,
    handleDeleteApplicationProgress
  );
}
