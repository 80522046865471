import { call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import UpdateLoanIncome from "Communication/ApiClasses/UpdateLoanIncomeRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleUpdateLoanIncome(action) {
  yield put(doLoadingStart(STRINGS.INCOME.API_KEYS.LOAN_UPDATE_INCOME));
  const updateLoanIncome = new UpdateLoanIncome(
    action.data.applicantId,
    action.data.finAnalysisId,
    action.data.financialId,
    action.data.payLoad
  );
  try {
    const result = yield call(updateLoanIncome.getData);
    yield put({
      type: actionTypes.LOAN_UPDATE_INCOME_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.LOAN_UPDATE_INCOME_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.INCOME.API_KEYS.LOAN_UPDATE_INCOME));
  }
}

// watcher
function* updateLoanIncomeWatcherSaga() {
  yield takeEvery(actionTypes.LOAN_UPDATE_INCOME, handleUpdateLoanIncome);
}

export default updateLoanIncomeWatcherSaga;
