import React from "react";
import "./Footer.scss";
import STRINGS from "Constants/Strings";

const Footer = () => {
  return (
    <footer className="[ footer ] [ container-fluid ]">
      <div className="[ row ] [ align-items-center ]">
        <div className="[ col-lg-6 ]">
          <div className="[ footer-container__item__text ]">
            <p>{STRINGS.FOOTER.COPYRIGHT}</p>
            <p>{STRINGS.FOOTER.FI_LEGAL_NAME}</p>
          </div>
        </div>
        <div className="[ col-lg-6 ]">
          <div className="[ footer__mobetize-text ]">
            <p>
              {STRINGS.FOOTER.MOBETIZE}
              <span className="[ appVersion ]">
                v. {process.env.REACT_APP_VERSION}
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
