import { getApiImplementation } from "../BaseApi";

export default class BaseApiUploadRequest {
  getData = () => {
    const accessToken = sessionStorage.getItem("token");
    const body = this.getBody();
    return getApiImplementation()
      .callApi(this.getMethod(), this.getUrl(), body, accessToken)
      .then((response) => {
        if (!response) {
          throw new Error("responese undefined");
        }
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}
