import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import TermsJsonRequest from "Communication/ApiClasses/TermsJsonRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleJsonData(action) {
  yield put(doLoadingStart(STRINGS.MEMBERCREATION.API_KEYS.TERMS_JSON));
  const jsonApi = new TermsJsonRequest();
  try {
    const result = yield call(jsonApi.getData);
    yield put({
      type: actionTypes.TERMS_JSON_RESPONSE,
      success: result,
    });
    if (action.callback) {
      yield call(action.callback, result);
    }
  } catch (error) {
    yield put({
      type: actionTypes.TERMS_JSON_ERROR,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.MEMBERCREATION.API_KEYS.TERMS_JSON));
  }
}

// watcher
export default function* jsonWatcherSaga() {
  yield takeLatest(actionTypes.TERMS_JSON_REQUEST, handleJsonData);
}
