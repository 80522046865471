import * as actionTypes from "Redux/Actions/actionTypes";

const updateApplicantData = (
  applicationDetails,
  applicationId,
  callback = () => {}
) => {
  return {
    type: actionTypes.UPDATE_APPLICANT_REQUEST,
    data: applicationDetails,
    applicationId,
    callback,
  };
};

export default updateApplicantData;
