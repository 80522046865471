import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { ESIGN_CREATE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class CreateEsignDocRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = [];

  url = ESIGN_CREATE_URL;

  constructor(applicationId) {
    super();

    const paramList = [applicationId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
