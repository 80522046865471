import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withVaultHOC from "Hoc/VaultHOC/VaultHOC";
import doLogout from "Redux/Actions/LogoutAction";
import STRINGS from "Constants/Strings";
// import component
import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import MODALMESSAGES from "Constants/Messages";
import {
  HTTP_STATUS,
  TOKEN_KEY,
  VERIFICATION_CODE,
  IS_SET_PASSWORD_VISIT,
} from "Communication/Constants";
import SetPassword from "./SetPassword";
import {
  doGetPasswordCriteria,
  doUpdatePassword,
} from "./Actions/SetPasswordAction";

class SetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: "", // popup modal type (success or error)
      nextComponent: "",
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
    };
  }

  componentDidMount = () => {
    const {
      doGetPswdCriteria,
      forgotPasswordState,
      registerPasswordState,
      history,
      otpVerify,
    } = this.props;
    console.log("componentDidMount", otpVerify);
    sessionStorage.setItem(IS_SET_PASSWORD_VISIT, true);
    // if (
    //   !forgotPasswordState.success &&
    //   !registerPasswordState.success &&
    //   !otpVerify.success
    // ) {
    //   history.push(ROUTES.SIGN_IN);
    // }

    // calls doGetPasswordCriteria on SetPassword Page mount
    doGetPswdCriteria();
  };

  componentDidUpdate = (prevProps) => {
    const { updatePasswordStatus, forgotPasswordState, history } = this.props;
    console.log(
      "componentDidUpdate",
      updatePasswordStatus,
      forgotPasswordState
    );
    if (updatePasswordStatus !== prevProps.updatePasswordStatus) {
      if (updatePasswordStatus.success.status === HTTP_STATUS.OK) {
        sessionStorage.removeItem(IS_SET_PASSWORD_VISIT);
        let nextComponentRoute =
          forgotPasswordState.success.status === HTTP_STATUS.OK
            ? ROUTES.SIGN_IN
            : ROUTES.EXISTING_MEMBER;
        if (
          sessionStorage.getItem(
            STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
          )
        ) {
          nextComponentRoute = ROUTES.VAULT_DASHBOARD;
        }
        if (history.location.pathname === ROUTES.CHANGE_PASSWORD) {
          this.setModalState({
            showModal: true,
            modalType: AppConstants.MODALTYPE.SUCCESS,
            modal: {
              title: MODALMESSAGES.CHANGE_PASSWORD.SUCCESSMSG,
              description: "",
            },
            nextComponent: ROUTES.SIGN_IN,
          });
        } else {
          this.setModalState({
            showModal: true,
            modalType: AppConstants.MODALTYPE.SUCCESS,
            modal: {
              title:
                forgotPasswordState.success.status === HTTP_STATUS.OK
                  ? MODALMESSAGES.RECOVERPASSWORD.SUCCESSMSG
                  : MODALMESSAGES.SETPASSWORD.SUCCESSMSG,
              description: "",
            },

            nextComponent: nextComponentRoute,
          });
        }
      } else {
        this.setModalState({
          showModal: true,
          modalType: AppConstants.MODALTYPE.FAILURE,
          modal: {
            title:
              forgotPasswordState.success.status === HTTP_STATUS.OK
                ? MODALMESSAGES.RECOVERPASSWORD.ERRORMSG
                : MODALMESSAGES.SETPASSWORD.ERRORMSG,
            description: "",
          },
          nextComponent: ROUTES.VAULT_PASSWORD,
        });
      }
    }
  };

  componentWillUnmount() {
    console.log("componentWillUnmount");
    const { updatePasswordStatus, doLogOut, history } = this.props;
    const token = sessionStorage.getItem(TOKEN_KEY);
    if (token && updatePasswordStatus.success === "") {
      sessionStorage.removeItem(TOKEN_KEY); // remove login token from storage
      sessionStorage.removeItem(IS_SET_PASSWORD_VISIT);
      doLogOut((res) => {
        if (res.status === HTTP_STATUS.OK) {
          if (history.location.pathname === ROUTES.VAULT_DASHBOARD) {
            history.push(ROUTES.MARKETPLACE);
          } else if (history.location.pathname === VERIFICATION_CODE) {
            history.push(ROUTES.SIGN_IN);
          }
        }
      });
    }
  }

  setModalState = (modal) => {
    this.setState(modal);
  };

  setRedirectpage = (forgotPasswordStatus, redirectUrl) => {
    this.setModalState({
      showModal: true,
      modalType: AppConstants.MODALTYPE.SUCCESS,
      modal: {
        title:
          forgotPasswordStatus === HTTP_STATUS.OK
            ? MODALMESSAGES.RECOVERPASSWORD.SUCCESSMSG
            : MODALMESSAGES.SETPASSWORD.SUCCESSMSG,
        description: "",
      },
      nextComponent:
        forgotPasswordStatus === HTTP_STATUS.OK ? ROUTES.SIGN_IN : redirectUrl,
    });
  };

  /**
   *
   * @param {string} password represents
   * password value to be set for the current
   * register user.
   */

  handleSetPasswordRequest = (password) => {
    const { doUpdatePswd, doGetPswdCriteria } = this.props;
    // setPasswordRequest object for update password
    const setPasswordRequest = {
      newPassword: password,
    };
    doUpdatePswd(setPasswordRequest);
    doGetPswdCriteria();
  };

  doLogout = () => {
    console.log("doLogout");
    const { doLogOut, history } = this.props;
    doLogOut((res) => {
      if (res.status === HTTP_STATUS.OK) {
        history.push(ROUTES.SIGN_IN);
      }
    });
  };

  handleBack = () => {
    this.doLogout();
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  render() {
    const { modalType, modal, showModal, nextComponent } = this.state;
    const { history, passwdCriteria, prepareVaultProductList } = this.props;
    return (
      <SetPassword
        handleSetPasswordRequest={this.handleSetPasswordRequest}
        history={history}
        type={modalType}
        modal={modal}
        showModal={showModal}
        toggleModal={this.toggleModal}
        nextComponent={nextComponent}
        passwdCriteria={passwdCriteria.passwordCriteria}
        doLogout={this.doLogout}
        prepareVaultProductList={prepareVaultProductList}
        handleBack={this.handleBack}
      />
    );
  }
}

SetPasswordContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  passwdCriteria: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  doUpdatePswd: PropTypes.func.isRequired,
  doGetPswdCriteria: PropTypes.func.isRequired,
  updatePasswordStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  forgotPasswordState: PropTypes.objectOf(PropTypes.any),
  registerPasswordState: PropTypes.objectOf(PropTypes.any),
  otpVerify: PropTypes.objectOf(PropTypes.any),
  doLogOut: PropTypes.func.isRequired,
  prepareVaultProductList: PropTypes.func,
};

SetPasswordContainer.defaultProps = {
  history: {},
  passwdCriteria: {},
  forgotPasswordState: {},
  registerPasswordState: {},
  otpVerify: {},
  prepareVaultProductList: () => {},
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetPswdCriteria: () => dispatch(doGetPasswordCriteria()),
    doUpdatePswd: (userDetails) => dispatch(doUpdatePassword(userDetails)),
    doLogOut: (callback) => dispatch(doLogout(callback)),
  };
};
const mapStateToProps = (state) => ({
  updatePasswordStatus: state.UpdatePasswordReducer,
  passwdCriteria: state.GetPasswordCriteriaReducer.success,
  registerPasswordState: state.RegisterReducer,
  forgotPasswordState: state.ForgotPasswordReducer,
  otpVerify: state.JoinApplicantReducer.otpVerify,
});

const SetPasswordWrapper = withVaultHOC(SetPasswordContainer);

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordWrapper);
