import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import AddLoanVehicle from "Communication/ApiClasses/AddLoanVehicleRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleAddLoanVehicle(action) {
  yield put(doLoadingStart(STRINGS.SECURITY_VEHICLE.API_KEYS.LOAN_ADD_VEHICLE));
  const addLoanVehicle = new AddLoanVehicle(
    action.data.applicationId,
    action.data.payLoad
  );
  try {
    const result = yield call(addLoanVehicle.getData);
    yield put({
      type: actionTypes.LOAN_ADD_VEHICLE_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.LOAN_ADD_VEHICLE_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.SECURITY_VEHICLE.API_KEYS.LOAN_ADD_VEHICLE)
    );
  }
}

// watcher
function* addLoanVehicleWatcherSaga() {
  yield takeLatest(actionTypes.LOAN_ADD_VEHICLE, handleAddLoanVehicle);
}

export default addLoanVehicleWatcherSaga;
