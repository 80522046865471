import * as actionTypes from "Redux/Actions/actionTypes";

export const updateGettingStarted = (userdata, callback) => {
  return {
    type: actionTypes.GETTINGSTARTED_REQUEST,
    data: userdata,
    callback,
  };
};

export const fetchGettingStartedBranch = () => {
  return {
    type: actionTypes.GETTINGSTARTED_BRANCH_REQUEST,
  };
};

export const fetchGettingStartedResidentStatus = () => {
  return {
    type: actionTypes.GETTINGSTARTED_RESIDENT_REQUEST,
  };
};
