import BaseApiRequest from "./BaseApiRequest";
import { RESET_PASSWORD_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class SetPasswordRequest extends BaseApiRequest {
  method = METHOD.HTTP.PUT;

  body = { newPassword: "" };

  constructor(userDetail) {
    super();
    this.body.newPassword = userDetail.newPassword;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return RESET_PASSWORD_URL;
  };

  getBody = () => {
    return this.body;
  };
}
