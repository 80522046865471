import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import SendEsignPackageRequest from "Communication/ApiClasses/SendEsignPackageRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import { ERROR_CODE } from "Communication/Constants";
import GetApplicationProperty from "../../../Communication/ApiClasses/GetApplicationProperty";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// worker
function* handleSendEsignPackage(action) {
  yield put(
    doLoadingStart(
      STRINGS.SIGNATURE.API_KEYS.ESIGN_SEND,
      STRINGS.SIGNATURE.ESIGN_SEND_MESSAGE
    )
  );
  const sendEsignPackage = new SendEsignPackageRequest(
    action.data.applicationId
  );
  const getApplicationProperty = new GetApplicationProperty({
    applicationId: action.data.applicationId,
  });
  try {
    let success = false;
    const delayTime = 3000;
    let ctr = 0;
    while (!success) {
      try {
        console.log("calling esign");
        const result = yield call(sendEsignPackage.getData);
        yield put({
          type: actionTypes.ESIGN_SEND_SUCCESS,
          response: result,
        });
        yield call(action.callback, result);
        success = true;
      } catch (e) {
        const errorObj = e.response?.data;
        if (errorObj?.code === ERROR_CODE.DOCS_STILL_CREATING) {
          console.log("calling get app property");
          const applicationProperty = yield call(
            getApplicationProperty.getData
          );
          console.log("applicationProperty", applicationProperty);
          if (applicationProperty.data.inReview) {
            yield call(action.callback, applicationProperty);
            success = true;
          } else {
            yield delay(delayTime);
            ctr += 1;
            if (ctr < 3) {
              yield put(doLoadingStart(STRINGS.SIGNATURE.API_KEYS.ESIGN_SEND));
            }
          }
        } else {
          success = true;
          yield put({
            type: actionTypes.ESIGN_SEND_ERROR,
            error: e,
          });
        }
      }
    }
  } catch (e) {
    yield put({
      type: actionTypes.ESIGN_SEND_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.SIGNATURE.API_KEYS.ESIGN_SEND));
  }
}

// watcher
function* sendEsignPackageWatcherSaga() {
  yield takeLatest(actionTypes.ESIGN_SEND, handleSendEsignPackage);
}

export default sendEsignPackageWatcherSaga;
