import React, { Component } from "react";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";

class NetworkHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offline: false,
    };
  }

  componentDidMount() {
    window.addEventListener(STRINGS.NETWORK.OFFLINE, () => {
      this.setState({ offline: true });
    });
    window.addEventListener(STRINGS.NETWORK.ONLINE, () => {
      this.setState({ offline: false });
    });
  }

  render() {
    const { offline } = this.state;
    return (
      offline && (
        <PopupModal
          showModal
          type={AppConstants.MODALTYPE.INFORMATION}
          title={STRINGS.NETWORK.ERROR}
          description={STRINGS.NETWORK.NETWORK_CONNECTIVITY_ISSUE}
          showPopupModalButtons={false}
        />
      )
    );
  }
}
export default NetworkHandler;
