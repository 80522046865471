import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  response: null,
  error: null,
};

const GetApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_APPLICANT_SUCCESS:
      return {
        ...state,
        response: action.response,
        error: null,
      };
    case actionTypes.GET_APPLICANT_ERROR:
      return {
        ...state,
        error: action.error,
        response: null,
      };
    default:
      return state;
  }
};

export default GetApplicationReducer;
