import axios from "axios";
import getErrorMessageFromErrorCode from "Constants/ServerErrors";
import { ERROR_CODE, METHOD, HTTP_STATUS } from "Communication/Constants";

// constants
import { URLS, LOGOUT_URL, PROCESS_URL } from "./ApiUrls";

// error lookup
import errorLookup from "./ErrorLookup";
import CustomBaseApi from "./CustomBaseApi";
import { showError } from "../Utils/LogicUtilities";

export default class CustomAxios {
  constructor() {
    this.axios = axios.create({
      baseURL: "",
    });

    this.responseInterceptor = this.axios.interceptors.response.use(
      (res) => {
        console.log("response interceptor", res);
        if (res.data.error) {
          const errorObj = errorLookup(res.data.error);
          //this.showErrorModal(errorObj.code, errorObj.message);
          return errorObj;
        }
        res.isError = false;
        return res;
      },

      (error) => {
        if (error.response?.config.url === PROCESS_URL) {
          return Promise.reject(error);
        }
        if (error.response) {
          const originalReq = error.config;

          if (
            error.response.status === HTTP_STATUS.BAD_REQUEST ||
            error.response.status === HTTP_STATUS.UNPROCESSABLE_ENTITY
          ) {
            console.log(error);
            if (error.response.config.url.includes("refresh")) {
              // do nothing
              // TODO need to handle this
            } else {
              if (
                ERROR_CODE.DATA_NOT_AVAILABLE !== error.response.data?.code &&
                ERROR_CODE.FAIL_ASSERTION !== error.response.data?.code &&
                ERROR_CODE.DOCS_STILL_CREATING !== error.response.data?.code
              ) {
                if (showError(error)) {
                  this.showErrorModal(
                    error.response.data.code,
                    getErrorMessageFromErrorCode(
                      error.response.data.code,
                      error.response.data
                    )
                  );
                }
              }
            }
          } else if (
            error.response.status === HTTP_STATUS.INTERNAL_SERVER_ERROR
          ) {
            /**
             * DATA_NOT_AVAILABLE: When user scan document it takes time to get data back from server
             * DOCS_STILL_CREATING: When sending docs but are still being created
             * So we make a call one after another until we receive some data from backend, we do not show error modal for each call.
             */
            if (
              ERROR_CODE.DATA_NOT_AVAILABLE !== error.response.data.code &&
              ERROR_CODE.FAIL_ASSERTION !== error.response.data.code &&
              ERROR_CODE.DOCS_STILL_CREATING !== error.response.data.code
            ) {
              if (showError(error)) {
                this.showErrorModal(
                  error.response.data.code,
                  getErrorMessageFromErrorCode(
                    HTTP_STATUS.INTERNAL_SERVER_ERROR,
                    error.response.data
                  )
                );
              }
            }
          } else if (error.response.status === HTTP_STATUS.NOT_FOUND) {
            /**
             * Developer information print in log
             * User pop up should have some meaningfull message. add in server errors
             */
            console.log(`Current URL: ${error.response.config.url}.`);
            this.showErrorModal(
              HTTP_STATUS.NOT_FOUND,
              getErrorMessageFromErrorCode(HTTP_STATUS.NOT_FOUND.toString(), {})
            );
          } else if (
            error.response.status === HTTP_STATUS.FORBIDDEN ||
            error.response.status === HTTP_STATUS.UNAUTHORISED
          ) {
            if (error.response.config.url === LOGOUT_URL) {
              this.goToLoginPage(true);
            } else {
              //TODO show session expired
              this.goToLoginPage(true);
            }
          } else {
            if (showError(error)) {
              this.showErrorModal(
                error.response.data.code,
                getErrorMessageFromErrorCode(
                  error.response.data.code,
                  error.response.data
                )
              );
            }
          }
        } else {
          this.showErrorModal(0, error.toString());
        }
        return Promise.reject(error);
      }
    );
  }

  callApi(method, url, requestBody, headers) {
    const config = {
      headers: headers,
      method,
      url,
      // timeout: TIMEOUT_DURATION,
    };
    if (headers) {
      config.headers = headers;
    }
    console.log("callApi", config);
    config[method !== METHOD.HTTP.GET ? "data" : "params"] = requestBody;
    return this.axios(config);
  }

  destructor = () => {
    this.axios.interceptors.response.eject(this.responseInterceptor);
  };
}
