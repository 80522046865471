import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetIDTypeList from "Communication/ApiClasses/GetIDTypeList";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGetIDType() {
  yield put(doLoadingStart(STRINGS.ABOUTYOU.API_KEYS.GET_ID_TYPE));
  const getIdTypeList = new GetIDTypeList();
  try {
    const result = yield call(getIdTypeList.getData);
    yield put({
      type: actionTypes.GET_ID_TYPE_SUCCESS,
      response: result,
    });
  } catch (e) {
    yield put({
      type: actionTypes.GET_ID_TYPE_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.ABOUTYOU.API_KEYS.GET_ID_TYPE));
  }
}

// watcher
function* getIdTypeWatcherSaga() {
  yield takeLatest(actionTypes.GET_ID_TYPE, handleGetIDType);
}

export default getIdTypeWatcherSaga;
