import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import CreateApplication from "Communication/ApiClasses/CreateApplication";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleCreateApllication(action) {
  yield put(
    doLoadingStart(
      STRINGS.ABOUTYOU.API_KEYS.CREATE_APPLICATION,
      action.data.message ? action.data.message : null
    )
  );
  const createApp = new CreateApplication(action.data);
  try {
    const result = yield call(createApp.getData);
    yield put({
      type: actionTypes.CREATE_APPLICATION_SUCCESS,
      response: result,
      formData: { ...action.requestData, ...action.additionalData },
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.CREATE_APPLICATION_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.ABOUTYOU.API_KEYS.CREATE_APPLICATION));
  }
}

// watcher
function* createApplicationWatcherSaga() {
  yield takeLatest(actionTypes.CREATE_APPLICATION, handleCreateApllication);
}

export default createApplicationWatcherSaga;
