import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import UpdateLendingProduct from "Communication/ApiClasses/UpdateLendingProductRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleUpdateLoanLendingProduct(action) {
  yield put(
    doLoadingStart(STRINGS.LOAN_DETAILS.API_KEYS.UPDATE_LENDING_PRODUCT)
  );
  const updateLendingProduct = new UpdateLendingProduct(
    action.data.applicationId,
    action.data.doximProductId,
    action.data.payLoad
  );
  try {
    const result = yield call(updateLendingProduct.getData);
    yield put({
      type: actionTypes.UPDATE_LENDING_PRODUCT_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_LENDING_PRODUCT_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.LOAN_DETAILS.API_KEYS.UPDATE_LENDING_PRODUCT)
    );
  }
}

// watcher
function* updateLoanLendingProductWatcherSaga() {
  yield takeLatest(
    actionTypes.UPDATE_LENDING_PRODUCT,
    handleUpdateLoanLendingProduct
  );
}

export default updateLoanLendingProductWatcherSaga;
