import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import SupportMessageRequests from "Communication/ApiClasses/SupportMessageRequests";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleSupportMessage(action) {
  yield put(doLoadingStart(STRINGS.APPLY_PRODUCT.API_KEYS.SUPPORT_MESSAGE));
  const supportMessage = new SupportMessageRequests(action.data);
  try {
    const result = yield call(supportMessage.getData);
    yield put({
      type: actionTypes.SUPPORT_MESSAGE_SUCCESS,
      response: result,
      formData: { ...action },
    });
    if (action.callback) {
      yield call(action.callback, result);
    }
  } catch (e) {
    yield put({
      type: actionTypes.SUPPORT_MESSAGE_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.APPLY_PRODUCT.API_KEYS.SUPPORT_MESSAGE));
  }
}
// watcher
export default function* productWatcherSaga() {
  yield takeLatest(actionTypes.SUPPORT_MESSAGE, handleSupportMessage);
}
