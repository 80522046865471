import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import SecurityVehicle from "./SecurityVehicle";
import {
  getLoanLoanSecurity,
  updateLoanSecurity,
} from "../LoanDetails/Actions/LoanDetailsAction";
import {
  addLoanVehicle,
  getLoanVehicle,
  updateLoanVehicle,
} from "../Asset/Actions/AssetActions";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";

class SecurityVehicleContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      vehicle: {},
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      products,
      getActiveProduct,
      continuehandler,
      actionBack,
      handleBack,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    if (activeProduct.category !== AppConstants.LOAN_CATEGORY.AUTO) {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE);
      }
    } else {
      this.initialize(activeProduct);
    }
  }

  initialize(activeProduct) {
    const { doGetLoanVehicle } = this.props;
    if (activeProduct.vehicle?.securityId) {
      doGetLoanVehicle(
        activeProduct.applicationId,
        activeProduct.vehicle.securityId,
        (res) => {
          console.log(res);
          const vehicle = {
            year: res.data.year,
            make: res.data.make,
            model: res.data.model,
            serial: res.data.serial,
            securityId: res.data.securityId,
          };
          this.setState({
            vehicle,
          });
        }
      );
    }
  }

  showModal = () => {
    this.setState({});
  };

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      products,
      getActiveProduct,
      continuehandler,
      doAddLoanVehicle,
      doUpdateLoanVehicle,
      doUpdateProductToVault,
      doUpdateLoanSecurity,
      finInfo,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    if (data.securityId) {
      doUpdateLoanVehicle(
        activeProduct.applicationId,
        data.securityId,
        { ...data, openingValue: activeProduct.loanDetails.loanAmount },
        (res) => {
          console.log(res);
          products[0]["vehicle"] = data;
          doUpdateProductToVault(products, () => {
            continuehandler(
              null,
              AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE
            );
          });
        }
      );
    } else {
      doAddLoanVehicle(
        activeProduct.applicationId,
        { ...data, openingValue: activeProduct.loanDetails.loanAmount },
        (res) => {
          console.log(res);
          doUpdateLoanSecurity(
            activeProduct.applicationId,
            activeProduct.doximProductId,
            {
              securityId: res.data.securityId,
              link: true,
              marginPercent: finInfo.marginPercent,
              balanceOfPriors: 0,
              usingSecuredAmountAsPercentage: true,
              securedPercent: finInfo.securedPercent,
              securedAmount: 0,
            },
            (updateSecurityRes) => {
              console.log(updateSecurityRes);
              products[0]["vehicle"] = {
                ...data,
                securityId: res.data.securityId,
              };
              doUpdateProductToVault(products, () => {
                continuehandler(
                  null,
                  AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE
                );
              });
            }
          );
        }
      );
    }
  };

  togglePopupModal = () => {
    this.setState({});
  };

  render() {
    const { choice, vehicle } = this.state;
    const { getDataFromServer, handleBack, steps, activeStepID } = this.props;

    return (
      <>
        <SecurityVehicle
          handleContinue={this.handleContinue}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          vehicle={vehicle}
        />
      </>
    );
  }
}

SecurityVehicleContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

SecurityVehicleContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  actionBack: state.ApplicationReducer.actionBack,
  finInfo: state.MarketplaceReducer.finInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetLoanLoanSecurity: (applicationId, callback) =>
      dispatch(getLoanLoanSecurity(applicationId, callback)),
    doAddLoanVehicle: (applicationId, payLoad, callback) =>
      dispatch(addLoanVehicle(applicationId, payLoad, callback)),

    doGetLoanVehicle: (applicationId, securityId, callback) =>
      dispatch(getLoanVehicle(applicationId, securityId, callback)),
    doUpdateLoanVehicle: (applicationId, securityId, payLoad, callback) =>
      dispatch(updateLoanVehicle(applicationId, securityId, payLoad, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doUpdateLoanSecurity: (
      applicationId,
      lendingProductId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateLoanSecurity(applicationId, lendingProductId, payLoad, callback)
      ),
  };
};

const SecurityVehicleWrapper = withApplicantHOC(SecurityVehicleContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityVehicleWrapper);
