import BaseApiRequest from "./BaseApiRequest";
import { GLOBAL_VAULT_ADD_URL } from "../ApiUrls";
import { METHOD } from "../Constants";
import { formURL } from "../../Utils/CommonUtilities";

export default class AddGlobalVault extends BaseApiRequest {
  method = METHOD.HTTP.PUT;

  body = [];

  url = GLOBAL_VAULT_ADD_URL;

  constructor(reqData) {
    super();
    this.body = reqData.body;
    const { vaultKey } = reqData;
    const paramList = [vaultKey];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
