import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import UpdateLoanRealEstate from "Communication/ApiClasses/UpdateLoanRealEstateRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleUpdateLoanRealEstate(action) {
  yield put(
    doLoadingStart(STRINGS.PROPERTY_DETAILS.API_KEYS.LOAN_UPDATE_REAL_ESTATE)
  );
  const updateLoanRealEstate = new UpdateLoanRealEstate(
    action.data.applicationId,
    action.data.securityId,
    action.data.payLoad
  );
  try {
    const result = yield call(updateLoanRealEstate.getData);
    yield put({
      type: actionTypes.LOAN_UPDATE_REAL_ESTATE_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.LOAN_UPDATE_REAL_ESTATE_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.PROPERTY_DETAILS.API_KEYS.LOAN_UPDATE_REAL_ESTATE)
    );
  }
}

// watcher
function* updateLoanRealEstateWatcherSaga() {
  yield takeLatest(
    actionTypes.LOAN_UPDATE_REAL_ESTATE,
    handleUpdateLoanRealEstate
  );
}

export default updateLoanRealEstateWatcherSaga;
