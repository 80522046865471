import NumberFormat from "react-number-format";
import React from "react";
import AppConstants from "../../Constants/AppConstants";
import PropTypes from "prop-types";

const AmountDisplay = (props) => {
  const { amount } = props;
  return (
    <NumberFormat
      thousandsGroupStyle="thousand"
      value={amount}
      prefix={`${AppConstants.PREFIX.DOLLAR} `}
      decimalSeparator="."
      displayType="text"
      type="text"
      thousandSeparator
      allowNegative
      decimalScale={2}
      fixedDecimalScale
      isNumericString
    />
  );
};

AmountDisplay.propTypes = {
  amount: PropTypes.string,
};

AmountDisplay.defaultProps = {
  amount: 0,
};

export default AmountDisplay;
