/**
 * Class: BucketApiRequest
 * Method: GetData
 * Description: Extending class shoudl only define getUrl method.
 *              METHOD is by default HTTP GET and
 *              body is sent as {}
 */
import { METHOD } from "../Constants";
import { getApiImplementation } from "../BaseApi";

export default class BucketApiRequest {
  getData = () => {
    const accessToken = null;
    return getApiImplementation()
      .callApi(METHOD.HTTP.GET, this.getUrl(), {}, accessToken)
      .then((response) => {
        if (!response) {
          throw new Error("response undefined");
        }
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}
