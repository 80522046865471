import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";

class CostOfBorrowing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.COST_OF_BORROWING,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  setFormData = () => {};

  handleChange = (choice) => {
    const { handleChange } = this.props;
    handleChange(choice);
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    handleContinue();
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.COST_OF_BORROWING);
  };

  render() {
    const { choice } = this.state;
    const { steps, activeStepID } = this.props;
    console.log(steps, activeStepID);
    const actionComponent = (
      <div className="[ costs-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.COST_OF_BORROWING.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ row ] ">
              {/* FEES */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.FEES_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobfees"
                    className="[ form-control ]"
                    placeholder={STRINGS.COST_OF_BORROWING.FEES_LABEL}
                  />
                </>
                <br />
              </div>
              {/* AMOUNT REQUESTED */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.AMOUNT_REQUESTED_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobamountrequested"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.COST_OF_BORROWING.AMOUNT_REQUESTED_LABEL
                    }
                  />
                </>
                <br />
              </div>
              {/* TOTAL PRINCIPAL AMOUNT */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.TOTAL_PRINCIPAL_AMOUNT_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobtotalprincipalamount"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.COST_OF_BORROWING.TOTAL_PRINCIPAL_AMOUNT_LABEL
                    }
                  />
                </>
                <br />
              </div>
              {/* FINAL PAYMENT AMOUNT */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.FINAL_PAYMENT_AMOUNT_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobfinalpaymentamount"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.COST_OF_BORROWING.FINAL_PAYMENT_AMOUNT_LABEL
                    }
                  />
                </>
                <br />
              </div>
              {/* EQUIVALENCY RATE */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.EQUIVALENCY_RATE_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobequivalencyrate"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.COST_OF_BORROWING.EQUIVALENCY_RATE_LABEL
                    }
                  />
                </>
                <br />
              </div>
              {/* NEGATIVE AM RATE */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.NEGATIVE_AM_RATE_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobnegativeamrate"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.COST_OF_BORROWING.NEGATIVE_AM_RATE_LABEL
                    }
                  />
                </>
                <br />
              </div>
              {/* TOTAL AMOUNT */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.TOTAL_AMOUNT_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobtotalamount"
                    className="[ form-control ]"
                    placeholder={STRINGS.COST_OF_BORROWING.TOTAL_AMOUNT_LABEL}
                  />
                </>
                <br />
              </div>
              {/* TOTAL TO BE FINANCED */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.TOTAL_TO_BE_FINANCED_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobtotaltobefinanced"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.COST_OF_BORROWING.TOTAL_TO_BE_FINANCED_LABEL
                    }
                  />
                </>
                <br />
              </div>
              {/* TOTAL NO OF PAYMENTS */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.TOTAL_NO_OF_PAYMENTS_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobtotalnoofpayments"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.COST_OF_BORROWING.TOTAL_NO_OF_PAYMENTS_LABEL
                    }
                  />
                </>
                <br />
              </div>
              {/* APR */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.APR_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobapr"
                    className="[ form-control ]"
                    placeholder={STRINGS.COST_OF_BORROWING.APR_LABEL}
                  />
                </>
                <br />
              </div>
              {/* EQUIVALENCY RATE APR */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.EQUIVALENCY_RATE_APR_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobequivalencyrateapr"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.COST_OF_BORROWING.EQUIVALENCY_RATE_APR_LABEL
                    }
                  />
                </>
                <br />
              </div>
              {/* TOTAL INTEREST PAID */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {/* TOTAL COST OF CREDIT TITLE */}
                  <text style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {STRINGS.COST_OF_BORROWING.TOTAL_COST_OF_CREDIT_TITLE}
                  </text>
                  <hr />
                  {STRINGS.COST_OF_BORROWING.TOTAL_INTEREST_PAID_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobtotalinterestpaid"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.COST_OF_BORROWING.TOTAL_INTEREST_PAID_LABEL
                    }
                  />
                </>
                <br />
              </div>
              {/* TOTAL COST OF BORROWING */}
              <div className="[ col-12 ]">
                <div className="[ form-label ]">
                  {STRINGS.COST_OF_BORROWING.TOTAL_COST_OF_BORROWING_LABEL}
                </div>
                <>
                  <input
                    type="text "
                    name="cobtotalcostofborrowing"
                    className="[ form-control ]"
                    placeholder={
                      STRINGS.COST_OF_BORROWING.TOTAL_COST_OF_BORROWING_LABEL
                    }
                  />
                </>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

CostOfBorrowing.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

CostOfBorrowing.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default CostOfBorrowing;
