import BucketApiRequest from "./BucketApiRequest";
import { GET_APPLICANT_TYPE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetApplicantType extends BucketApiRequest {
  method = METHOD.HTTP.GET;

  body = {};

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return `${GET_APPLICANT_TYPE_URL}?t=${new Date().getTime()}`;
  };

  getBody = () => {
    return this.body;
  };
}
