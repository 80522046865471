import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import UpdateLoanVehicle from "Communication/ApiClasses/UpdateLoanVehicleRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleUpdateLoanVehicle(action) {
  yield put(
    doLoadingStart(STRINGS.SECURITY_VEHICLE.API_KEYS.LOAN_UPDATE_VEHICLE)
  );
  const updateLoanVehicle = new UpdateLoanVehicle(
    action.data.applicationId,
    action.data.securityId,
    action.data.payLoad
  );
  try {
    const result = yield call(updateLoanVehicle.getData);
    yield put({
      type: actionTypes.LOAN_UPDATE_VEHICLE_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.LOAN_UPDATE_VEHICLE_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.SECURITY_VEHICLE.API_KEYS.LOAN_UPDATE_VEHICLE)
    );
  }
}

// watcher
function* updateLoanVehicleWatcherSaga() {
  yield takeLatest(actionTypes.LOAN_UPDATE_VEHICLE, handleUpdateLoanVehicle);
}

export default updateLoanVehicleWatcherSaga;
