import BaseApiRequest from "./BaseApiRequest";
import { FIND_APPLICANT_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetLendingProductRequest extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = [];

  url = FIND_APPLICANT_URL;

  constructor(payLoad) {
    super();
    this.url = `${this.url}?inveriteRequestGuid=${payLoad.inveriteRequestGuid}`;
    if (payLoad.relationship) {
      this.url = `${this.url}&relationship=${payLoad.relationship}`;
    }
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
