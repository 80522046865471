import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import AddBeneficiariesApiRequest from "Communication/ApiClasses/AddBeneficiariesApiRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleAddBeneficiaries(action) {
  try {
    yield put(doLoadingStart(STRINGS.BENEFICIARIES.API_KEYS.ADD_BENEFICIARIES));
    const addBeneficiariesApi = new AddBeneficiariesApiRequest(action.data, [
      action.applicationId,
      action.contractProductId,
    ]);
    const result = yield call(addBeneficiariesApi.getData);
    yield put({
      type: actionTypes.ADD_BENEFICIARIES_SUCCESS,
      data: result.data,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.ADD_BENEFICIARIES_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.BENEFICIARIES.API_KEYS.ADD_BENEFICIARIES)
    );
  }
}

// watcher
export default function* getBeneficiaryRelationWatcherSaga() {
  yield takeLatest(actionTypes.ADD_BENEFICIARIES, handleAddBeneficiaries);
}
