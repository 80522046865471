import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import UpdateApplication from "../../Communication/ApiClasses/UpdateApplication";

// worker
function* handleUpdateApplication(action) {
  yield put(doLoadingStart(`${actionTypes.UPDATE_APPLICATION}_SAGA`));
  const updateApplicationApi = new UpdateApplication(
    action.applicationId,
    action.payLoad
  );
  try {
    const result = yield call(updateApplicationApi.getData);
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: `${actionTypes.UPDATE_APPLICATION}_ERROR`,
      error: error.response?.data.description,
    });
  } finally {
    yield put(doLoadingFinish(`${actionTypes.UPDATE_APPLICATION}_SAGA`));
  }
}

// watcher
export default function* handleUpdateApplicationSaga() {
  yield takeLatest(actionTypes.UPDATE_APPLICATION, handleUpdateApplication);
}
