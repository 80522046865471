import { call, put, takeLatest } from "redux-saga/effects";
import GetProductTemplte from "Communication/ApiClasses/GetProductTemplte";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGetProductTemplate(action) {
  yield put(doLoadingStart(STRINGS.COMMON.API_KEYS.GET_PRODUCT_TEMPLATE));
  const getProductTemplate = new GetProductTemplte();
  try {
    const result = yield call(getProductTemplate.getData);
    yield put({
      type: actionTypes.STORE_GET_PRODUCT_TEMPLATE,
      data: result.data,
      product: action.product,
    });
    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: actionTypes.STORE_GET_PRODUCT_TEMPLATE,
      error,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.COMMON.API_KEYS.GET_PRODUCT_TEMPLATE));
  }
}

// watcher
export default function* getProductTemplateSaga() {
  yield takeLatest(actionTypes.GET_PRODUCT_TEMPLATE, handleGetProductTemplate);
}
