import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import DeleteLoanLoanSecurity from "Communication/ApiClasses/DeleteLoanLoanSecurityRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleDeleteLoanLoanSecurity(action) {
  yield put(
    doLoadingStart(STRINGS.LOAN_DETAILS.API_KEYS.LOAN_DELETE_LOAN_SECURITY)
  );
  const deleteLoanLoanSecurity = new DeleteLoanLoanSecurity(
    action.data.applicationId,
    action.data.securityId
  );
  try {
    const result = yield call(deleteLoanLoanSecurity.getData);
    yield put({
      type: actionTypes.LOAN_DELETE_LOAN_SECURITY_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.LOAN_DELETE_LOAN_SECURITY_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.LOAN_DETAILS.API_KEYS.LOAN_DELETE_LOAN_SECURITY)
    );
  }
}

// watcher
function* deleteLoanLoanSecurityWatcherSaga() {
  yield takeLatest(
    actionTypes.LOAN_DELETE_LOAN_SECURITY,
    handleDeleteLoanLoanSecurity
  );
}

export default deleteLoanLoanSecurityWatcherSaga;
