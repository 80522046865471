import React, { Component } from "react";
import PropTypes from "prop-types";

class CheckBoxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  // handle change event of checkbox
  checkBoxHandler = () => {
    const { onChangeCheckBoxHandler, data } = this.props;
    const { checked } = this.state;
    this.setState((state) => ({ checked: !state.checked }));
    if (!checked) {
      onChangeCheckBoxHandler(data.code, data, true);
    } else {
      onChangeCheckBoxHandler(data.code, data, false);
    }
  };

  // Render checkbox
  renderCheckbox = (data) => {
    const { checked } = this.state;
    const { error } = this.props;

    if (data.description) {
      const consent = JSON.stringify(data.consent);
      return (
        <div className="[ mb-3 ]">
          <div className="[ row ] [ align-items-center ]">
            <div className="[ col-12 ] [ d-flex align-items-center ]">
              <div className="[ checkbox custom-control custom-checkbox ]">
                <input
                  type="checkbox"
                  name={data.mandatory ? "mandatory" : "notMandatory"}
                  value={consent}
                  id={data.code}
                  onChange={this.checkBoxHandler}
                  className="[ custom-control-input ]"
                />
                <label
                  htmlFor={data.code}
                  className="[ checkbox-text custom-control-label ]"
                >
                  {data.description}
                </label>
              </div>
            </div>

            <div className="[ error-msg ] [ col-12 ]">
              {data.mandatory && !checked ? error : ""}
            </div>
          </div>
        </div>
      );
    }
    return false;
  };

  render() {
    const { data } = this.props;

    return <div>{this.renderCheckbox(data)}</div>;
  }
}
CheckBoxComponent.propTypes = {
  data: PropTypes.shape(),
  onChangeCheckBoxHandler: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};
CheckBoxComponent.defaultProps = {
  data: {},
  onChangeCheckBoxHandler: () => {},
  error: [],
};

export default CheckBoxComponent;
