import * as actionTypes from "Redux/Actions/actionTypes";

export const callCreateInverite = (callback) => ({
  type: actionTypes.CREATE_INVERITE_REQUEST,
  callback,
});

export const callFetchInverite = (callback) => ({
  type: actionTypes.FETCH_INVERITE_REQUEST,
  callback,
});

export const callCreateInveriteAccountList = (callback) => ({
  type: actionTypes.CREATE_INVERITE_ACCOUNT_LIST_REQUEST,
  callback,
});

export const addApplicant = (payLoad, callback) => ({
  type: actionTypes.ADD_APPLICANT,
  data: payLoad,
  callback,
});

/**
 *
 * @param payLoad - inveriteRequestGuid and relationship
 * @param callback - memberNumberPresent, foundByMemberNumber, applicantId
 * @returns {{data, callback, type: string}}
 */
export const findApplicant = (payLoad, callback) => ({
  type: actionTypes.FIND_APPLICANT,
  data: payLoad,
  callback,
});

export const callFetchInveriteAccountList = (
  requestGuid,
  callback = () => {}
) => ({
  type: actionTypes.FETCH_INVERITE_ACCOUNT_LIST_REQUEST,
  data: {
    requestGuid,
  },
  callback,
});
