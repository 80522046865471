import BaseApiRequest from "./BaseApiRequest";
import { RECOVERPASSWORD_VERIFICATIONCODE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class RecoverPasswordRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    otpId: "",
    otp: "",
    newPassword: "",
  };

  constructor(userDetail) {
    super();
    this.body.otpId = userDetail.otpId;
    this.body.otp = userDetail.otp;
    this.body.newPassword = userDetail.newPassword;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return RECOVERPASSWORD_VERIFICATIONCODE_URL;
  };

  getBody = () => {
    const requestBody =
      this.body.newPassword !== ""
        ? this.body
        : { otpId: this.body.otpId, otp: this.body.otp };
    return requestBody;
  };
}
