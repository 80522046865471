import React, { Component } from "react";
import PropTypes from "prop-types";

import NumberFormat from "react-number-format";

import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";

import PopupModal from "Components/Common/PopupModal/PopupModal";
import Stepper from "Components/Common/Stepper/Stepper";
import AuthBody from "Components/Common/AuthBody/AuthBody";

import "./FundingAmount.scss";

class FundingAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fundAmount: "",
      fundAmountError: "",
      isShowFundAmountInput: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { fundAmountError } = this.props;
    if (
      fundAmountError !== "" &&
      fundAmountError !== prevProps.fundAmountError
    ) {
      this.setModalState(fundAmountError);
    }
  }

  setModalState = (fundAmountError) => {
    this.setState({ fundAmountError });
  };

  handleChangeAmount = (event, fundAmount) => {
    const { doAddFundAmount } = this.props;
    event.preventDefault();
    this.setState({
      fundAmount: fundAmount === STRINGS.FUND_ACCOUNT.OTHER ? "" : fundAmount,
      fundAmountError: "",
      isShowFundAmountInput: fundAmount === STRINGS.FUND_ACCOUNT.OTHER,
    });
    let amount = "";
    if (fundAmount !== STRINGS.FUND_ACCOUNT.OTHER) {
      amount = `${fundAmount}.00`;
    } else {
      amount = STRINGS.FUND_ACCOUNT.FUND_AMOUNT_SYMBOL;
    }
    doAddFundAmount(amount);
  };

  changeAmount = (event) => {
    const { doAddFundAmount } = this.props;
    const fundAmount = event.value;
    let amount = fundAmount;
    this.setState({ fundAmount, fundAmountError: "" }, () => {
      if (fundAmount) {
        amount = `$${fundAmount}`;
      } else {
        amount = STRINGS.FUND_ACCOUNT.FUND_AMOUNT_SYMBOL;
      }
      doAddFundAmount(amount);
    });
  };

  handleContinue = (e) => {
    e.preventDefault();
    const { fundAmount, fundAmountError } = this.state;
    const { handleContinue } = this.props;
    if (fundAmount && fundAmountError === "") {
      handleContinue(
        fundAmount.includes("$") ? fundAmount.replace("$", "") : fundAmount
      );
    } else if (fundAmountError) {
      this.setState({
        fundAmountError,
      });
    } else {
      this.setState({
        fundAmountError: STRINGS.FUND_ACCOUNT.FUND_ERROR_MSG,
      });
    }
  };

  render() {
    const { fundAmount, fundAmountError, isShowFundAmountInput } = this.state;
    const {
      fundMethod,
      type,
      title,
      description,
      toggleModal,
      showModal,
      handleBack,
      productName,
      popupBtnClick,
      closeBtnClick,
      finInfo,
    } = this.props;
    let amount = fundAmount;
    if (fundAmount) {
      if (!fundAmount.includes("$")) {
        amount = `$${fundAmount}`;
      }
      if (!fundAmount.includes(".")) {
        amount += ".00";
      }
    } else {
      amount = STRINGS.FUND_ACCOUNT.FUND_AMOUNT_SYMBOL;
    }
    const maxLimit = finInfo.paysafeMaxAmt ? finInfo.paysafeMaxAmt : 1000;
    const withValueLimit = ({ floatValue }) => floatValue <= maxLimit;
    const fundAccount = (
      <div className="[ fund-amount-container ]">
        <Stepper steps={AppConstants.FUND_ACCOUNT.FUND_STEPS} active={2} />

        <div className="[ form-title ]">
          {STRINGS.FUND_ACCOUNT.FUND_AMOUNT_TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.FUND_ACCOUNT.FUND_DEPOSIT_AMOUNT}
            </div>

            <div className="[ row ] [ custom-radios ]">
              {AppConstants.FUND_ACCOUNT.FUND_AMOUNT_CUR.map((button) => {
                return (
                  <div className="[ col-6 ] [ mb-3 ] " key={button.Id}>
                    <button
                      type="button"
                      className={
                        fundAmount === button.Name ||
                        (button.Id === 6 && isShowFundAmountInput)
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      onClick={(event) =>
                        this.handleChangeAmount(event, button.Name)
                      }
                    >
                      {button.Name}
                    </button>
                  </div>
                );
              })}
            </div>

            {isShowFundAmountInput && (
              <div className="[ drawer-content-container ]">
                <div className="[ form-label ]">
                  {STRINGS.FUND_ACCOUNT.FUND_AMOUNT}
                </div>

                <NumberFormat
                  type="text"
                  name="amount"
                  decimalScale="2"
                  prefix="$"
                  isNumericString
                  className={
                    fundAmountError.length > 0
                      ? "[ form-control ] [ invalid-input ]"
                      : "[ form-control ]"
                  }
                  placeholder={STRINGS.FUND_ACCOUNT.FUND_AMOUNT_SYMBOL}
                  value={fundAmount}
                  onValueChange={(value) => {
                    this.changeAmount(value);
                  }}
                  isAllowed={withValueLimit}
                />
              </div>
            )}
          </div>
          <div className="[ error-msg ]">{fundAmountError}</div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <>
        {showModal && (
          <PopupModal
            showModal={showModal}
            type={type}
            title={title}
            description={description}
            toggleModal={toggleModal}
            popupBtnClick={popupBtnClick}
            closeBtnClick={closeBtnClick}
          />
        )}
        <AuthBody
          actionComponent={fundAccount}
          memberCreation
          showFundAccount
          handleBack={handleBack}
          fundMethod={fundMethod}
          fundAmount={amount}
          productName={productName}
        />
      </>
    );
  }
}
FundingAmount.defaultProps = {
  fundMethod: "",
  type: "",
  title: "",
  description: "",
  showModal: false,
  fundAmountError: "",
  productName: "",
  popupBtnClick: () => {},
  closeBtnClick: () => {},
};
FundingAmount.propTypes = {
  handleContinue: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  fundMethod: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  fundAmountError: PropTypes.string,
  productName: PropTypes.string,
  popupBtnClick: PropTypes.func,
  closeBtnClick: PropTypes.func,
  doAddFundAmount: PropTypes.func.isRequired,
};

export default FundingAmount;
