import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import AccountPurposeRequest from "Communication/ApiClasses/AccountPurposeRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleAccountPurpose() {
  yield put(doLoadingStart(STRINGS.COMPLIANCE.API_KEYS.ACCOUNT_PURPOSE_LIST));
  const accountPurposeApi = new AccountPurposeRequest();
  try {
    const result = yield call(accountPurposeApi.getData);
    yield put({
      type: actionTypes.ADD_ACCOUNT_PURPOSE,
      accountPurposeList: result.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.ACCOUNT_PURPOSE_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.COMPLIANCE.API_KEYS.ACCOUNT_PURPOSE_LIST)
    );
  }
}

// watcher
export default function* accountPurposeWatcherSaga() {
  yield takeLatest(actionTypes.GET_ACCOUNT_PURPOSE, handleAccountPurpose);
}
