import BaseApiRequest from "./BaseApiRequest";
import { METHOD } from "../Constants";
import { LOGOUT_URL } from "../ApiUrls";

export default class LogoutRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;
  body = {};

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return LOGOUT_URL;
  };

  getBody = () => {
    return this.body;
  };
}
