import BaseApiRequest from "./BaseApiRequest";
import { GET_ACCOUNT_DETAILS } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetAccountRequest extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = {};

  url = `${GET_ACCOUNT_DETAILS}?t=${new Date().getTime()}`;

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
