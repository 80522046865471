import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import DeleteDocument from "Communication/ApiClasses/DeleteDocumentRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleDeleteDocument(action) {
  yield put(doLoadingStart(STRINGS.DOCUMENT_UPLOAD.API_KEYS.DELETE_DOCUMENT));
  const deleteDocument = new DeleteDocument(
    action.data.applicationId,
    action.data.documentId
  );
  console.log(action);
  try {
    const result = yield call(deleteDocument.getData);
    yield put({
      type: actionTypes.DELETE_DOCUMENT_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.DELETE_DOCUMENT_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.DOCUMENT_UPLOAD.API_KEYS.DELETE_DOCUMENT)
    );
  }
}

// watcher
function* deleteDocumentWatcherSaga() {
  yield takeLatest(actionTypes.DELETE_DOCUMENT, handleDeleteDocument);
}

export default deleteDocumentWatcherSaga;
