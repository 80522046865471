import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import CountryRequest from "Communication/ApiClasses/CountryApiRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleCountry() {
  yield put(
    doLoadingStart(STRINGS.EMPLOYMENT_AND_INCOME.API_KEYS.COUNTRY_LIST)
  );
  const countryApi = new CountryRequest();
  try {
    const result = yield call(countryApi.getData);
    yield put({
      type: actionTypes.ADD_COUNTRY_LIST,
      countryList: result.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.COUNTRY_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.EMPLOYMENT_AND_INCOME.API_KEYS.COUNTRY_LIST)
    );
  }
}

// watcher
export default function* countryWatcherSaga() {
  yield takeLatest(actionTypes.GET_COUNTRY_LIST, handleCountry);
}
