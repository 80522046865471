import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetBeneficiaryTypeApiRequest from "Communication/ApiClasses/GetBeneficiaryTypeApiRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGetBeneficiaryType() {
  try {
    yield put(
      doLoadingStart(STRINGS.BENEFICIARIES.API_KEYS.GET_BENEFICIARY_TYPE)
    );
    const getBeneficiaryTypeApi = new GetBeneficiaryTypeApiRequest();
    const result = yield call(getBeneficiaryTypeApi.getData);
    yield put({
      type: actionTypes.ADD_BENEFICIARY_TYPE_LIST,
      beneficiaryTypeList: result.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_BENEFICIARY_TYPE_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.BENEFICIARIES.API_KEYS.GET_BENEFICIARY_TYPE)
    );
  }
}

// watcher
export default function* getBeneficiaryTypeWatcherSaga() {
  yield takeLatest(actionTypes.GET_BENEFICIARY_TYPE, handleGetBeneficiaryType);
}
