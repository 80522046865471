import { call, put, takeLatest } from "redux-saga/effects";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import * as actionTypes from "Redux/Actions/actionTypes";
import FindApplicantRequest from "Communication/ApiClasses/FindApplicantRequest";
import AppConstants from "../../../Constants/AppConstants";

//  worker
function* handleCreateInverite(action) {
  yield put(
    doLoadingStart(actionTypes.FIND_APPLICANT + AppConstants.SAGA_WAIT)
  );
  const findApplicantRequest = new FindApplicantRequest(action.data);
  try {
    const result = yield call(findApplicantRequest.getData);
    yield call(action.callback, result);
  } finally {
    yield put(
      doLoadingFinish(actionTypes.FIND_APPLICANT + AppConstants.SAGA_WAIT)
    );
  }
}

//  watcher
export default function* createApiWatcherSaga() {
  yield takeLatest(actionTypes.FIND_APPLICANT, handleCreateInverite);
}
