import BaseApiRequest from "./BaseApiRequest";
import { UPDATE_APPLICANT_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

class UpdateFormRequest extends BaseApiRequest {
  method = METHOD.HTTP.PUT;

  url = UPDATE_APPLICANT_URL;

  body = {
    formDetails: {},
  };

  constructor(formDetails, aplicationId) {
    super();
    this.body.formDetails = formDetails;
    this.url = `${UPDATE_APPLICANT_URL}${aplicationId}`;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body.formDetails;
  };
}

export default UpdateFormRequest;
