import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import Fees from "./Fees";

class FeesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
    };
  }

  componentDidMount() {}

  handleChange = () => {};

  handleContinue = (choice) => {
    const { continuehandler } = this.props;
    continuehandler({ choice }, AppConstants.APPLICATIONSTEP.FEES);
  };

  render() {
    const { choice } = this.state;
    const { getDataFromServer, handleBack, steps, activeStepID } = this.props;

    return (
      <>
        <Fees
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
        />
      </>
    );
  }
}

FeesContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

FeesContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
  };
};

const FeesWrapper = withApplicantHOC(FeesContainer);

export default connect(mapStateToProps, mapDispatchToProps)(FeesWrapper);
