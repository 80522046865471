import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";

import STRINGS from "Constants/Strings";

import ProductVaultRequest from "Communication/ApiClasses/ProductVaultRequest";

import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";

// worker
function* handleProductVaultRequest(action) {
  yield put(doLoadingStart(STRINGS.MARKETPLACE.API_KEYS.PRODUCT_VAULT));
  const productVaultApi = new ProductVaultRequest(action.data);
  try {
    const result = yield call(productVaultApi.getData);
    yield put({
      type: actionTypes.UPDATE_VAULT_PRODUCT_LIST,
      data: action.data,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield call(action.callback, e.response);
  } finally {
    yield put(doLoadingFinish(STRINGS.MARKETPLACE.API_KEYS.PRODUCT_VAULT));
  }
}

// watcher
export default function* productVaultWatcherSaga() {
  yield takeLatest(
    actionTypes.UPDATE_PRODUCT_TO_VAULT,
    handleProductVaultRequest
  );
}
