import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import OtpVerifyInvite from "Communication/ApiClasses/OtpVerifyInvite";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleOtpVerifyInvite(action) {
  console.log(action);
  yield put(doLoadingStart(STRINGS.JOIN_APPLICANT.API_KEYS.OTP_VERIFY_INVITE));
  const otpVerifyInvite = new OtpVerifyInvite(action.data);

  try {
    const result = yield call(otpVerifyInvite.getData);
    console.log(result);
    yield put({
      type: actionTypes.OTP_VERIFY_INVITE_SUCCESS,
      response: result.data,
      formData: { ...action.requestData },
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.OTP_VERIFY_INVITE_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.JOIN_APPLICANT.API_KEYS.OTP_VERIFY_INVITE)
    );
  }
}

// watcher
function* otpVerifyInviteWatcherSaga() {
  yield takeLatest(actionTypes.OTP_VERIFY_INVITE, handleOtpVerifyInvite);
}

export default otpVerifyInviteWatcherSaga;
