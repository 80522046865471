import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { LOAN_GET_INCOME_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetLoanIncome extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = [];

  url = LOAN_GET_INCOME_URL;

  constructor(applicantId, finAnalysisId, financialId) {
    super();
    const paramList = [applicantId, finAnalysisId, financialId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
