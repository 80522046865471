import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import CheckEsignDocRequest from "../../../Communication/ApiClasses/CheckEsignDocRequest";

// worker
function* handleCheckEsignDoc(action) {
  yield put(doLoadingStart(`${actionTypes.ESIGN_CHECK}_SAGA`));
  const checkEsignDocRequest = new CheckEsignDocRequest(
    action.data.applicationId
  );
  try {
    const result = yield call(checkEsignDocRequest.getData);
    yield put({
      type: `${actionTypes.ESIGN_CHECK}_SUCCESS`,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: `${actionTypes.ESIGN_CHECK}_ERROR`,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(`${actionTypes.ESIGN_CHECK}_SAGA`));
  }
}

// watcher
function* checkEsignDocWatcherSaga() {
  yield takeLatest(actionTypes.ESIGN_CHECK, handleCheckEsignDoc);
}

export default checkEsignDocWatcherSaga;
