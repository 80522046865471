import BaseApiRequest from "./BaseApiRequest";
import { PRODUCT_VAULT_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class ProductVaultRequest extends BaseApiRequest {
  method = METHOD.HTTP.PUT;

  body = {};

  constructor(apiBody) {
    super();
    this.body = apiBody;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return PRODUCT_VAULT_URL;
  };

  getBody = () => {
    return this.body;
  };
}
