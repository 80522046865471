/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { isLogin } from "Utils";
import * as ROUTES from "Constants/Routes";

const PublicRoute = ({
  component: Component,
  restricted,
  showComponent,
  ...rest
}) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route

    <Route
      {...rest}
      render={(props) =>
        isLogin() && restricted ? (
          <Redirect to={ROUTES.SIGN_IN} />
        ) : (
          showComponent && (
            <Component
              {...props}
              showVault={rest.showVault}
              showVaultClicked={rest.showVaultClicked}
            />
          )
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  restricted: PropTypes.bool.isRequired,
  component: PropTypes.elementType,
  showComponent: PropTypes.bool,
};
PublicRoute.defaultProps = { component: {}, showComponent: false };

export default PublicRoute;
