import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { FINANCIALANALYSYS_FINANCIAL_LIST } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetFinancialAnalysisFinancialList extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = [];

  url = FINANCIALANALYSYS_FINANCIAL_LIST;

  constructor(applicantId, finAnalysisId) {
    super();
    const paramList = [applicantId, finAnalysisId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
