import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetLoanRealEstate from "Communication/ApiClasses/GetLoanRealEstateRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGetLoanRealEstate(action) {
  yield put(
    doLoadingStart(STRINGS.PROPERTY_DETAILS.API_KEYS.LOAN_GET_REAL_ESTATE)
  );
  const getLoanRealEstate = new GetLoanRealEstate(
    action.data.applicationId,
    action.data.securityId
  );
  try {
    const result = yield call(getLoanRealEstate.getData);
    yield put({
      type: actionTypes.LOAN_GET_REAL_ESTATE_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.LOAN_GET_REAL_ESTATE_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.PROPERTY_DETAILS.API_KEYS.LOAN_GET_REAL_ESTATE)
    );
  }
}

// watcher
function* getLoanRealEstateWatcherSaga() {
  yield takeLatest(actionTypes.LOAN_GET_REAL_ESTATE, handleGetLoanRealEstate);
}

export default getLoanRealEstateWatcherSaga;
