import { call, put, takeLatest } from "redux-saga/effects";
import GetLoanProduct from "Communication/ApiClasses/GetLoanProduct";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// worker
function* handleGetLoanProductSaga(action) {
  yield put(doLoadingStart(STRINGS.LOAN_DETAILS.API_KEYS.GET_LOAN_PRODUCT));
  const getLoanProduct = new GetLoanProduct();
  try {
    const result = yield call(getLoanProduct.getData);
    yield put({
      type: actionTypes.GET_LOAN_PRODUCT_SUCCESS,
      response: result.data,
    });

    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: actionTypes.GET_LOAN_PRODUCT_ERROR,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.LOAN_DETAILS.API_KEYS.GET_LOAN_PRODUCT));
  }
}
// watcher
export default function* getGetLoanProductWatcherSaga() {
  yield takeLatest(actionTypes.GET_LOAN_PRODUCT, handleGetLoanProductSaga);
}
