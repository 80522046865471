import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  success: "",
  error: "",
  username: "",
};

const SigninReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNIN_RESPONSE:
      return {
        ...state,
        success: action.success,
        error: "",
      };
    case actionTypes.SIGNIN_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
      };
    case actionTypes.ADD_USERNAME:
      return {
        ...state,
        username: action.data,
      };
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default SigninReducer;
