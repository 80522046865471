import BaseApiRequest from "./BaseApiRequest";
import { METHOD } from "../Constants";
import {
  FINANCIALANALYSYS_FINANCIAL_LIST,
  INVERITE_FETCH_ACCOUNT_LIST_API_URL,
} from "../ApiUrls";
import { formURL } from "Utils/CommonUtilities";

export default class FetchInveriteAccountListRequest extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  url = INVERITE_FETCH_ACCOUNT_LIST_API_URL;

  body = {};

  constructor(requestId) {
    super();
    const paramList = [requestId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
