import * as actionTypes from "Redux/Actions/actionTypes";

export const doConfirmApplication = (req, callback) => {
  return {
    type: actionTypes.CONFIRM_APPLY_REQUEST,
    data: req,
    callback,
  };
};

export const doConfirmSuccess = (req, callback) => {
  return {
    type: actionTypes.CONFIRM_RESULT_UPLOADED,
    data: req,
    callback,
  };
};
