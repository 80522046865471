import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetEsignRequest from "Communication/ApiClasses/GetEsignRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGetEsign(action) {
  yield put(doLoadingStart(STRINGS.SIGNATURE.API_KEYS.ESIGN_GET));
  const getEsign = new GetEsignRequest(action.data.applicationId);
  try {
    const result = yield call(getEsign.getData);
    yield put({
      type: actionTypes.ESIGN_GET_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.ESIGN_GET_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.SIGNATURE.API_KEYS.ESIGN_GET));
  }
}

// watcher
function* getEsignWatcherSaga() {
  yield takeLatest(actionTypes.ESIGN_GET, handleGetEsign);
}

export default getEsignWatcherSaga;
