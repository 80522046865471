import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { APPLICATION_SLOT_INVITE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class InviteApplicationSlot extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    email: "",
    misstion: "",
  };

  url = APPLICATION_SLOT_INVITE_URL;

  constructor(reqData) {
    super();
    this.body = reqData.body;
    const { slotId, applicationId } = reqData;
    const paramList = [applicationId, slotId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
