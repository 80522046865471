import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import SubmitVerify from "Communication/ApiClasses/SubmitVerify";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import { ERROR_CODE } from "Communication/Constants";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const verifyMessages = [
  "Verifying your identity... please wait.",
  "Validating ID to Facial Biometrics... please wait",
  "Validating Social Biometrics... please wait.",
];

// worker
function* handlesubmitVerify(action) {
  yield put(
    doLoadingStart(STRINGS.ABOUTYOU.API_KEYS.SUBMIT_VERIFY, verifyMessages[0])
  );
  const submitVerify = new SubmitVerify(action.data);
  try {
    let success = false;
    const delayTime = 5000;
    let ctr = 0;
    while (!success) {
      try {
        const result = yield call(submitVerify.getData);
        yield call(action.callback, result);
        yield put({
          type: actionTypes.SAVE_CREATE_APP_STATUS,
          formData: { ...result.data },
        });
        success = true;
      } catch (e) {
        console.log("saga error", e);
        const errorObj = e.response?.data;
        if (
          errorObj?.code === ERROR_CODE.DATA_NOT_AVAILABLE ||
          errorObj?.code === ERROR_CODE.FAIL_ASSERTION
        ) {
          yield delay(delayTime);
          ctr += 1;
          if (ctr < 5) {
            yield put(
              doLoadingStart(
                STRINGS.ABOUTYOU.API_KEYS.SUBMIT_VERIFY,
                verifyMessages[ctr]
              )
            );
          }
        } else {
          success = true;
          yield call(action.callback, e.response);
          yield put({
            type: actionTypes.SUBMIT_VERIFY_ERROR,
            error: e,
          });
        }
      }
    }
  } catch (e) {
    yield call(action.callback, e.response);
    yield put({
      type: actionTypes.SUBMIT_VERIFY_ERROR,
      error: e,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.ABOUTYOU.API_KEYS.SUBMIT_VERIFY));
  }
}

// watcher
function* submitVerifyWatcherSaga() {
  yield takeLatest(actionTypes.SUBMIT_VERIFY, handlesubmitVerify);
}

export default submitVerifyWatcherSaga;
