import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import ConfirmAccountApplyRequest from "Communication/ApiClasses/ConfirmAccountApplyRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleConfirmAccountCreate(action) {
  yield put(
    doLoadingStart(STRINGS.CONFIRM_CREATION.API_KEYS.CONFIRM_ACCOUNT_CREATE)
  );
  const confirmAccountApplyAPI = new ConfirmAccountApplyRequest(action.data);
  try {
    const result = yield call(confirmAccountApplyAPI.getData);
    yield put({
      type: actionTypes.CONFIRM_APPLY_RESPONSE,
      success: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.CONFIRM_APPLY_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.CONFIRM_CREATION.API_KEYS.CONFIRM_ACCOUNT_CREATE)
    );
  }
}

// watcher
export default function* confirmAccountCreateWatcherSaga() {
  yield takeLatest(
    actionTypes.CONFIRM_APPLY_REQUEST,
    handleConfirmAccountCreate
  );
}
