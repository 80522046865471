import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  success: "",
  error: "",
  requestGuid: "",
};

const ExistingUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXISTINGUSER_RESPONSE:
      return {
        ...state,
        success: action.success,
        error: "",
      };
    case actionTypes.EXISTINGUSER_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
      };
    case actionTypes.RESET:
      return initialState;
    case actionTypes.STORE_INVERITE_GUID:
      return {
        ...state,
        requestGuid: action.data.requestGuid,
      };
    default:
      return state;
  }
};

export default ExistingUserReducer;
