/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GettingStartedRequest from "Communication/ApiClasses/GettingStartedRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGettingStarted(action) {
  yield put(doLoadingStart(STRINGS.GETTING_STARTED.API_KEYS.GETTING_STARTED));
  const gettingData = action.data;
  const gettingApi = new GettingStartedRequest(gettingData);
  try {
    const result = yield call(gettingApi.getData);
    yield put({
      type: actionTypes.GETTINGSTARTED_RESPONSE,
      success: result,
    });
    yield call(action.callback, result);
    yield put({
      type: actionTypes.GETTING_STARTED_FORM_DATA,
      data: gettingData,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GETTINGSTARTED_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.GETTING_STARTED.API_KEYS.GETTING_STARTED)
    );
  }
}

// watcher
export function* gettingStartedWatcherSaga() {
  yield takeLatest(actionTypes.GETTINGSTARTED_REQUEST, handleGettingStarted);
}
