import * as actionTypes from "Redux/Actions/actionTypes";

export const createEsignDoc = (applicationId, callback = () => {}) => ({
  type: actionTypes.ESIGN_CREATE,
  data: {
    applicationId,
  },
  callback,
});

export const checkEsignDoc = (applicationId, callback = () => {}) => ({
  type: actionTypes.ESIGN_CHECK,
  data: {
    applicationId,
  },
  callback,
});

export const sendEsignPackage = (applicationId, callback = () => {}) => ({
  type: actionTypes.ESIGN_SEND,
  data: {
    applicationId,
  },
  callback,
});

export const getEsign = (applicationId, callback = () => {}) => ({
  type: actionTypes.ESIGN_GET,
  data: {
    applicationId,
  },
  callback,
});
