import { call, put, takeLatest } from "redux-saga/effects";
import GetReasonCodes from "Communication/ApiClasses/GetReasonCodes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import * as actionTypes from "Redux/Actions/actionTypes";

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(actionTypes.GET_REASON_CODES));
  const getReasonCodes = new GetReasonCodes();
  try {
    const result = yield call(getReasonCodes.getData);
    yield put({
      type: `${actionTypes.GET_REASON_CODES}_SUCCESS`,
      response: result.data,
    });

    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: `${actionTypes.GET_REASON_CODES}_ERROR`,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(actionTypes.GET_REASON_CODES));
  }
}
// watcher
export default function* getSaga() {
  yield takeLatest(actionTypes.GET_REASON_CODES, handleSaga);
}
