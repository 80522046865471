import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  data: [],
  success: "",
  error: "",
  formData: "",
};

const IncomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAN_ADD_INCOME_SUCCESS:
      return {
        ...state,
        loanProducts: {
          success: true,
          error: "",
          data: action.response,
        },
      };
    case actionTypes.LOAN_ADD_INCOME_ERROR:
      return {
        ...state,
        success: "",
        error: action.error,
        data: "",
      };
    case actionTypes.LOAN_ADD_INCOME_SUCCESS:
      return {
        ...state,
        loanProducts: {
          success: true,
          error: "",
          data: action.response,
        },
      };
    case actionTypes.LOAN_ADD_INCOME_ERROR:
      return {
        ...state,
        success: "",
        error: action.error,
        data: "",
      };
    default:
      return state;
  }
};
export default IncomeReducer;
