import BaseApiRequest from "./BaseApiRequest";
import { METHOD } from "../Constants";
import { PROCESS_URL } from "../ApiUrls";

export default class ProcessApiRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    paymentToken: "",
    applicationId: "",
    amount: "",
    paymentType: "",
    merchantRefNum: "",
  };

  constructor(paymentDetails) {
    super();
    this.body.paymentToken = paymentDetails.paymentToken;
    this.body.applicationId = paymentDetails.applicationId;
    this.body.amount = paymentDetails.amount;
    this.body.paymentType = paymentDetails.paymentType;
    this.body.merchantRefNum = paymentDetails.merchantRefNum;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return PROCESS_URL;
  };

  getBody = () => {
    return this.body;
  };
}
