import { call, put, takeLatest } from "redux-saga/effects";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetTermProductRequest from "../../../Communication/ApiClasses/GetTermProductRequest";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// worker
function* handleGetTermProductSaga(action) {
  yield put(doLoadingStart(`${actionTypes.GET_TERM_PRODUCT}_SAGA`));
  console.log(action);
  const getTermProductRequest = new GetTermProductRequest(
    action.data.applicationId,
    action.data.doximProductId
  );
  try {
    const result = yield call(getTermProductRequest.getData);
    yield put({
      type: `${actionTypes.GET_TERM_PRODUCT}_SUCCESS`,
      response: result.data,
    });
    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: `${actionTypes.GET_TERM_PRODUCT}_ERROR`,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(`${actionTypes.GET_TERM_PRODUCT}_SAGA`));
  }
}
// watcher
export default function* getTermProductWatcherSaga() {
  yield takeLatest(actionTypes.GET_TERM_PRODUCT, handleGetTermProductSaga);
}
