import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetLoanVehicle from "Communication/ApiClasses/GetLoanVehicleRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleGetLoanVehicle(action) {
  yield put(doLoadingStart(STRINGS.SECURITY_VEHICLE.API_KEYS.LOAN_GET_VEHICLE));
  const getLoanVehicle = new GetLoanVehicle(
    action.data.applicationId,
    action.data.securityId
  );
  try {
    const result = yield call(getLoanVehicle.getData);
    yield put({
      type: actionTypes.LOAN_GET_VEHICLE_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.LOAN_GET_VEHICLE_ERROR,
      error: e.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.SECURITY_VEHICLE.API_KEYS.LOAN_GET_VEHICLE)
    );
  }
}

// watcher
function* getLoanVehicleWatcherSaga() {
  yield takeLatest(actionTypes.LOAN_GET_VEHICLE, handleGetLoanVehicle);
}

export default getLoanVehicleWatcherSaga;
