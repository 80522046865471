import { call, put, takeLatest } from "redux-saga/effects";
import ProcessApiRequest from "Communication/ApiClasses/ProcessApiRequest";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

//  worker
function* handleProcess(action) {
  yield put(doLoadingStart(STRINGS.FUND_ACCOUNT.API_KEYS.FUND_PROCESSOR));
  const processApi = new ProcessApiRequest(action.data);
  try {
    const result = yield call(processApi.getData);
    yield call(action.callback, result, null);
  } catch (e) {
    yield call(action.callback, null, e);
  } finally {
    yield put(doLoadingFinish(STRINGS.FUND_ACCOUNT.API_KEYS.FUND_PROCESSOR));
  }
}
//  watcher
export default function* FundProcessWatcherSaga() {
  yield takeLatest(actionTypes.PROCESS_REQUEST, handleProcess);
}
