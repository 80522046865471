import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { LOAN_UPDATE_VEHICLE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class UpdateLoanVehicle extends BaseApiRequest {
  method = METHOD.HTTP.PUT;

  body = [];

  url = LOAN_UPDATE_VEHICLE_URL;

  constructor(applicationId, securityId, payLoad) {
    super();
    this.body = payLoad;
    const paramList = [applicationId, securityId];
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
