import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import EditBeneficiaryRequest from "Communication/ApiClasses/EditBeneficiaryRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleEditBeneficiary(action) {
  try {
    yield put(
      doLoadingStart(STRINGS.BENEFICIARIES.API_KEYS.EDIT_BENEFICIARIES)
    );
    const editBeneficiaryRequest = new EditBeneficiaryRequest(action.data, [
      action.applicationId,
      action.contractProductId,
      action.beneficiaryId,
    ]);
    const result = yield call(editBeneficiaryRequest.getData);

    yield put({
      type: actionTypes.EDIT_BENEFICIARIES_SUCCESS,
      data: result.data,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.EDIT_BENEFICIARIES_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.BENEFICIARIES.API_KEYS.EDIT_BENEFICIARIES)
    );
  }
}

// watcher
export default function* editBeneficiaryWatcherSaga() {
  yield takeLatest(actionTypes.EDIT_BENEFICIARIES, handleEditBeneficiary);
}
