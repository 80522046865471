import React from "react";
import "../../../Styles/globalStyles.scss";
import "../../../Styles/custom.scss";
import PropTypes from "prop-types";

const ScreenHeader = (props) => {
  const { screenHeader } = props;
  return (
    <div className="[ screen-title-container ]">
      <div className="[ screen-title-text ]">{screenHeader}</div>
    </div>
  );
};
ScreenHeader.propTypes = {
  screenHeader: PropTypes.string,
};
ScreenHeader.defaultProps = {
  screenHeader: "",
};
export default ScreenHeader;
