import * as actionTypes from "Redux/Actions/actionTypes";

export const getAccountPurposeList = () => {
  return {
    type: actionTypes.GET_ACCOUNT_PURPOSE,
  };
};

export const getIndicateWhoList = () => {
  return {
    type: actionTypes.GET_INDICATE_WHO,
  };
};
